@mixin snackbar($color) {
  > div {
    border-left: 5px solid $color !important;

    .mat-mdc-snack-bar-action,
    button {
      color: $color !important;
    }
  }
}

.sympheny-snackbar {
  margin-top: 125px !important;

  &--warn {
    @include snackbar(var(--sympheny-warning));
  }

  &--error {
    @include snackbar(var(--sympheny-error));
  }

  &--success {
    @include snackbar(var(--sympheny-success));
  }

  &--info {
    @include snackbar(var(--sympheny-info));
  }
}
