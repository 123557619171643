.sympheny--scenario-stepper {
  .mat-horizontal-stepper-header-container {
    position: fixed;
    z-index: 101;
    // top: var(--sympheny-header);
    right: 0;
    left: var(--sidenav--min-width);
    width: inherit;
    transition: margin-left 0.2s ease-in-out;
    margin-bottom: 20px;
    background-color: #fff;

    .sympheny--navigation_open & {
      left: var(--sidenav--full-width);
    }
  }

  .mat-horizontal-content-container {
    padding: 0 10px !important;
    padding-top: 82px !important;
  }
}
