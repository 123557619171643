table {
  th {
    .mat-icon {
      font-size: 18px !important;
      vertical-align: middle;
      text-align: center;
    }
  }
}

.analysis-table {
  @apply grid items-center w-full max-w-screen-2xl;
  @apply gap-x-2  xl:gap-x-4;
  grid-template-columns: minmax(0, 1fr) 120px 100px 180px;

  @screen xl {
    grid-template-columns: minmax(0, 1fr) 150px 150px 300px;
  }
}
