:root {
  --sidenav--full-width: 230px;
  --sidenav--min-width: 72px;
}

.sympheny {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content 1fr;
  grid-template-areas:
    'banner banner'
    'header header'
    'menu main';

  &--banner {
    display: block;
    grid-area: banner;
  }

  &--header {
    display: block;
    grid-area: header;
  }

  &--menu {
    display: block;
    grid-area: menu;
    z-index: 10;
    transition: width 0.2s ease-in-out;
    width: var(--sidenav--min-width);

    &_open {
      width: var(--sidenav--full-width);
    }
  }

  &--main {
    display: block;
    grid-area: main;
    overflow: scroll;
    background-color: var(--sympheny-white);
    transition: margin-left 0.2s ease-in-out;
  }
}
