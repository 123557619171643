:root {
  --sympheny-error: #fa1e13;
  --sympheny-info: #266eb2;
  --sympheny-warning: #fdba31;
  --sympheny-success: #00ca00;
  --sympheny-primary: #1d9682;
  --sympheny-white: white;
  --sympheny-dark: #3b3b3b;
  --sympheny-light-gray: #979797;
  --sympheny-map-control-bg: #3b3b3b;
  --sympheny-map-control: white;
  --sympheny-map-control-bg-hover: #7a7a7ac2;
}
