.sympheny-map-popup {
  padding-left: 10px;
  margin-top: -10px;
  .mapboxgl-popup-tip {
    display: none;
  }
  > * {
    padding: 0;
    background-color: transparent;
  }
}
