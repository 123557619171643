.sympheny-scenario--content {
  display: flex;
  flex-direction: column;
  gap: var(--sympheny-space-large);

  &__row_4 {
    display: grid;
    align-items: center;
    gap: var(--sympheny-space-large);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    > * {
      display: block;
    }
    > div,
    .mat-mdc-form-field {
      width: 100%;
    }
  }
  &__row_2 {
    display: flex;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: var(--sympheny-space-large);
    > * {
      width: 50%;
    }
    > div,
    .mat-mdc-form-field {
      width: 100%;
    }
  }
  &__row_3 {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--sympheny-space-large);
    > * {
      width: 33%;
    }
    > div,
    .mat-mdc-form-field {
      width: 100%;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    gap: var(--sympheny-space-large);
  }
}
