.card {
  @apply bg-base-100 shadow-xl;

  &-project {
    @apply max-w-[256px] card w-full h-[230px];
    //@apply transition-shadow drop-shadow shadow;
    @apply bg-base-100;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateZ(0);
    transition:
      transform ease 0.5s,
      box-shadow ease 0.5s;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
    }

    .beta-project {
      @apply absolute left-0 top-0 h-8 w-8;

      > div {
        @apply absolute left-[-32px] top-[8px] w-28 transform -rotate-45;
        @apply bg-gray-600 text-center text-white font-semibold py-1 shadow;

        background: var(--sympheny-beta-color);
      }
    }
  }

  &--shadow-sm,
  .execution-results .card {
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}
