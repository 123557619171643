.markdown {
  .markdown-clipboard-button {
    @apply border border-opacity-50 rounded;
    @apply bg-gray-50 text-xs;
    @apply shadow;
    @apply p-1 w-12;
    transition: all 250ms ease-out;
  }

  .markdown-clipboard-button:hover {
    @apply text-primary;
  }

  .markdown-clipboard-button:active {
    @apply scale-95;
  }

  .markdown-clipboard-button.copied {
    @apply bg-gray-100;
    @apply text-primary;
  }

  ul {
    @apply list-disc list-inside ml-2 mb-2;
  }
  ol {
    @apply list-decimal list-inside ml-2 mb-2;
  }

  blockquote {
    @apply p-2 my-2 bg-gray-50 border-l-4 border-gray-300;

    p {
      @apply text-gray-800  dark:text-white p-0 m-0;
    }
  }

  pre {
    @apply mb-4 mx-4;

    height: min-content;
    max-height: 200px;
    transform-origin: top;
    transition: height 0.3s linear, max-height 0s 0.3s linear;
    &:hover {
      height: min-content;
      max-height: min-content;
    }
  }

  .mermaid {
    @apply flex justify-center;
  }

  h1 {
    @apply mt-4;
  }
}
