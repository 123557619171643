@use 'theme' as *;

@use 'colors' as *;
@use 'space' as *;

@use 'components/snackbar' as *;
@use 'components/sidenav' as *;
@use 'components/table' as *;
@use 'components/app-container' as *;
@use 'components/scenario-stepper' as *;
@use 'components/scenario' as *;
@use 'components/tooltip' as *;
@use 'components/form' as *;
@use 'components/markdown' as *;
@use 'components/radio' as *;
@use 'components/card'as *;
@use 'components/project-card' as *;

@use 'map/marker'as *;
@use 'map/popup' as *;

@use 'openlayers/openlayers' as *;

@use '_text' as *;
@use 'global' as *;
@use 'mat-override' as *;

@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-primary underline;
  }
}

.hint {
  @apply text-gray-light py-4;
}

.w-full,
.full-width {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.btn:hover {
  @apply bg-gray-100 border-gray-100;
}

.btn-primary {
  @apply text-white;

  &:hover {
    @apply bg-primary opacity-80;
  }
}

body {
  @apply text-sm;
}

* {
  scrollbar-color: initial;
}
