@use '@angular/material' as mat;

// Use the default configuration.
$sympheny-typography: mat.m2-define-typography-config(
  $font-family: Roboto,
);

@include mat.typography-hierarchy($sympheny-typography);
@include mat.core();

$sympheny-palette: (
  50: #e4f2f0,
  100: #bbe0da,
  200: #8ecbc1,
  300: #61b6a8,
  400: #3fa695,
  500: #1d9682,
  600: #1a8e7a,
  700: #15836f,
  800: #117965,
  900: #0a6852,
  A100: #9affe5,
  A200: #67ffd8,
  A400: #34ffca,
  A700: #1affc4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$sympheny-primary: mat.m2-define-palette($sympheny-palette);
$sympheny-accent: mat.m2-define-palette($sympheny-palette, A200, A100, A400);
// Create the theme object (a Sass map containing all of the palettes).
//$sympheny-theme: mat.define-light-theme($sympheny-primary, $sympheny-accent);

$sympheny-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $sympheny-primary,
      accent: $sympheny-accent,
    ),
    //typography: $sympheny-typography,
    density: 0,
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sympheny-theme);

@mixin sym-light-theme($theme) {
  //@include sym-app-theme($theme);
  //@include sym-nav-theme($theme);
}

@include sym-light-theme($sympheny-theme);

// overwrite default variables
html {
  --mat-form-field-subscript-text-line-height: 12px;
  --mat-form-field-subscript-text-size: 10px;
}
